import { cn } from "@/lib/utils";
import { IUser } from "@/types";

export function PartyMembers({
  user,
  members,
}: {
  user: Partial<IUser>;
  members?: IUser[];
}) {
  console.log({ user, members });
  return (
    <div className="flex flex-row justify-center items-center">
      {members?.map((member: IUser) => {
        return (
          <div
            className={cn(
              "flex p-3 rounded-full bg-gray-300 justify-center items-center mr-2 uppercase font-bold title raised border-2 border-black",
              member.status === "ready" && "bg-green-300",
              member.status === "submitted" && "bg-yellow-300",
              member.status === "waiting" && "bg-purple-300",
              member.username === user.username && "bg-blue-300"
            )}
            key={member.id}
          >
            {member.username}
          </div>
        );
      })}
    </div>
  );
}
