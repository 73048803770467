import { useEffect, useState } from "react";
import Confetti from "react-confetti";
import { Button } from "./components/ui/button";
import { Card } from "@/components/ui/card";
import { cn } from "./lib/utils";
import { ICard, IRound, IUser } from "./types";
import { PartyMembers } from "./components/PartyMembers";
import { PartyCoins } from "./components/PartyCoins";

export function Player({
  socket,
  user,
  round,
  members,
  setUser,
}: {
  socket?: any;
  user: Partial<IUser>;
  round: IRound;
  members: IUser[];
  setUser: any;
}) {
  const [submitting, setSubmitting] = useState(false);

  const toggleCard = (card: ICard) => {
    if (user?.hand?.find((c) => c.id === card.id)) {
      return setUser({ hand: user.hand.filter((c) => c.id !== card.id) });
    }
    if ((user.coins || 0) < card.coins) return;
    setUser({ hand: [...(user?.hand || []), card] });
  };

  const submit = () => {
    setSubmitting(true);
    socket?.send(JSON.stringify({ type: "submit" }));
  };

  const hasSubmitted = round?.submissions?.find((s) => s.playerId === user.id);
  const hasWon = hasSubmitted?.won;

  useEffect(() => {
    setSubmitting(false);
  }, [user.submissions?.length]);

  useEffect(() => {
    if (!hasWon) return;
    const bloop = new Audio("/audio/bloop-3.mp3");
    bloop.play();
  }, [hasWon]);

  return (
    <>
      <div className="flex flex-row justify-between items-center">
        <PartyCoins coins={user.coins || 0} />
        <PartyMembers user={user} members={members} />
        <div className="flex flex-1">
          <div className="ml-auto"></div>
        </div>
      </div>
      {hasSubmitted ? (
        <div className="flex flex-col flex-1 items-center mb-20 justify-center w-full">
          {hasWon ? (
            <>
              <Confetti />
              <div className="text-4xl">You won!</div>
            </>
          ) : null}
          <div className="text-2xl">Your Submission:</div>
          <img
            className="p-1 border-2 raised border-black rounded-lg bg-[#711DB0] h-[300px] w-[300px]"
            src={hasSubmitted.url}
          />
          <div className="flex flex-col items-center mb-20 justify-center w-full">
            {round.submissions.length > 1 ? (
              <>
                <div className="text-2xl mt-3">Other Submissions:</div>

                <div className="flex flex-row overflow-scroll">
                  {round.submissions
                    .filter((s) => s.playerId !== user.id)
                    .map((s) => {
                      return (
                        <div key={s.id}>
                          <img
                            src={s.url}
                            className="p-1 border-2 raised border-black rounded-lg bg-[#711DB0] h-[300px] w-[300px]"
                          />

                          <div className="flex p-5 my-8 rounded-full bg-green-300 justify-center items-center mr-2">
                            {members.find((m) => m.id === s.playerId)?.username}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </>
            ) : null}
          </div>
        </div>
      ) : (
        <div className="flex flex-col m-auto">
          <div className="flex flex-row items-center mb-20 justify-center w-full">
            {user.hand?.map((card: ICard) => {
              return (
                <CoinCard
                  key={card.id}
                  toggleCard={toggleCard}
                  card={card}
                  active
                />
              );
            })}
          </div>
          <PromptRiver
            cards={round.cards}
            coins={user.coins || 0}
            hand={user.hand}
            toggleCard={toggleCard}
          />

          <div className="raised flex p-5 fixed bottom-[16px] right-[16px] left-[16px] flex-row items-center justify-center bg-white border-4 border-black rounded-[6px]">
            <Button
              className="raised title p-8 text-4xl rounded-lg bg-[#711DB0] text-white"
              onClick={submit}
              disabled={!user.hand?.length || submitting}
            >
              Submit
            </Button>
          </div>
        </div>
      )}

      {/* footer */}
    </>
  );
}

function PromptRiver({
  cards,
  hand,
  coins,
  toggleCard,
}: {
  cards?: ICard[];
  coins: number;
  hand?: ICard[];
  toggleCard: (card: ICard) => void;
}) {
  return (
    <div className="river flex flex-row m-auto">
      {cards?.map((card: ICard) => {
        const active = !!hand?.find((c) => c.id === card.id);
        const disabled = !active && coins < card.coins;
        return (
          <CoinCard
            active={active}
            disabled={disabled}
            key={card.id}
            card={card}
            toggleCard={toggleCard}
          />
        );
      })}
    </div>
  );
}

function CoinCard({
  toggleCard,
  card,
  active,
  disabled,
}: {
  toggleCard: (a: ICard) => void;
  card: ICard;
  active?: boolean;
  disabled?: boolean;
}) {
  return (
    <Card
      key={card.id}
      onClick={() => toggleCard(card)}
      className={cn(
        "raised flex flex-col justify-center items-center mr-3 p-8 relative cursor-pointer border-2 border-gray-700 min-w-[200px]",
        disabled && "bg-gray-400",
        active && "bg-[#C21292] text-white"
      )}
    >
      <div className="absolute top-[-8px] right-[-8px] w-[40px] h-[40px] flex justify-center items-center bg-yellow-300 rounded-[50%] text-black font-bold">
        {card.coins}
      </div>
      <div>{card.content}</div>
    </Card>
  );
}
