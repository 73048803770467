import { Button } from "./components/ui/button";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
} from "@/components/ui/card";
import { useRealtimeState } from "./hooks/party";
import { IUser } from "./types";

export function UserInfo() {
  const [user, setUser] = useRealtimeState<Partial<IUser>>(
    "self",
    { username: "" },
    true
  );
  const begin = () => {
    setUser({ status: "ready" });
  };

  return (
    <Card className="raised border-2 border-gray-700 bg-[#dcedf7]">
      <CardHeader className="flex flex-1 items-center">
        <h2 className="border-2 border-gray-300 py-1 px-2 rounded-full bg-white">
          @{user.username}
        </h2>
      </CardHeader>
      <CardContent></CardContent>
      <CardFooter className="justify-center">
        <Button
          className="title rounded-full bg-[#711DB0] p-8 text-3xl"
          onClick={() => begin()}
        >
          Ready!
        </Button>
        <br />
        <br />
        <br />
        <br />
      </CardFooter>
    </Card>
  );
}
