import { cn } from "@/lib/utils";

export function PartyCoins({ coins }: { coins: number }) {
  return (
    <div className="flex flex-1 flex-row items-center p-3">
      {Array.from({ length: coins }).map((_, i) => {
        const disabled = i >= coins;
        return (
          <div
            key={i}
            className={cn(
              "flex w-[50px] h-[50px] rounded-[25px] bg-yellow-300 justify-center items-center mr-2 border-2 border-black",
              disabled && "bg-gray-200"
            )}
          >
            COIN
          </div>
        );
      })}
    </div>
  );
}
