import { useParams } from "react-router-dom";
import { usePartySocket } from "partysocket/react";
import { PARTYKIT_HOST } from "./config";

import { PartyContext, useParty, useRealtimeState } from "./hooks/party";
import { UserInfo } from "./UserInfo";
import { IRound, IUser } from "./types";
import { Leader } from "./Leader";
import { Player } from "./Player";
import { cardpacks } from "./data";
import { useEffect, useState } from "react";

// const STARTING_COINS = 5;

console.log({ PARTYKIT_HOST });

export function GameRoom() {
  const { roomId } = useParams();
  const socket = usePartySocket({
    host: PARTYKIT_HOST,
    room: roomId,
    onError: console.error,
    onClose: (e) => console.log("closed", e),
  });

  return (
    <PartyContext.Provider value={socket}>
      <GamePage />
    </PartyContext.Provider>
  );
}

function GamePage() {
  const [user, setUser] = useRealtimeState<Partial<IUser>>("self", {
    username: "",
  });

  const socket = useParty();

  const [members] = useRealtimeState<IUser[]>("members", []);
  const [rounds] = useRealtimeState<IRound[]>("rounds", []);
  const [leader] = useRealtimeState<string>("leader", "");

  const [memCount, setMemCount] = useState(0);

  useEffect(() => {
    setMemCount(members.length);
    if (memCount >= members.length) {
      return;
    }
    if (members.length < 2) return;
    const bloop = new Audio("/audio/bloop-1.mp3");
    bloop.play();
  }, [members.length]);

  useEffect(() => {
    if (rounds.length < 2) return;
    const bloop = new Audio("/audio/bloop-2.mp3");
    bloop.play();
  }, [rounds.length]);

  const round = rounds[rounds.length - 1 || 0];

  const isLeader = leader === user.id;

  if (!round) {
    return <div className="">Loading...</div>;
  }

  return (
    <div className="rainbow flex flex-col flex-1 h-[100vh] p-1">
      <div className="flex flex-1 flex-col border-4 border-black rounded-[6px] bg-[#ffa732] dotted">
        <div className="flex items-center justify-between w-full p-3">
          <a href="/">
            <h1 className="rainbow-text title text-4xl">PARTYGAME</h1>
          </a>
          {rounds.length > 0 ? (
            <div className="flex flex-col items-center text-2xl">
              <div>ROUND: {rounds.length}</div>
              <div>
                THEME: {cardpacks.find((v) => v.id === round.theme)?.name}
              </div>
            </div>
          ) : null}
        </div>
        {isLeader ? (
          <Leader socket={socket} user={user} round={round} members={members} />
        ) : user.status === "ready" ||
          user.status === "submitted" ||
          user.status === "waiting" ? (
          <Player
            socket={socket}
            user={user}
            round={round}
            members={members}
            setUser={setUser}
          />
        ) : (
          <div className="w-[400px] m-auto">
            <UserInfo />
          </div>
        )}
      </div>
    </div>
  );
}
