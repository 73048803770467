export const cardpacks = [
  {
    id: "1_animals",
    name: "Animals",
  },
  {
    id: "2_food",
    name: "Food",
  },
  {
    id: "3_people",
    name: "People",
  },
  {
    id: "4_places",
    name: "Places",
  },
  {
    id: "5_starwars",
    name: "Star Wars",
  },
  {
    id: "6_marvel",
    name: "Marvel",
  },
];
