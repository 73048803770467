import { Routes, Route, BrowserRouter, useNavigate } from "react-router-dom";
import { useState } from "react";
import { Button } from "./components/ui/button";
import { Input } from "./components/ui/input";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { GameRoom } from "./GameRoom";
import "./App.css";

const ROOM_CODE_LENGTH = 4;

function randomString(length: number) {
  return Math.round(
    Math.pow(36, length + 1) - Math.random() * Math.pow(36, length)
  )
    .toString(36)
    .slice(1)
    .toUpperCase();
}

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/game/:roomId" element={<GameRoom />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

function Home() {
  const navigate = useNavigate();
  const [room, setRoom] = useState<string>("");

  const newGame = () => {
    navigate(`/game/${randomString(ROOM_CODE_LENGTH)}`);
  };
  const joinGame = () => {
    navigate(`/game/${room}`);
  };

  return (
    <div className="rainbow flex flex-col flex-1 h-[100vh] w-full justify-center items-center">
      <Card className="min-w-[400px] raised border-2 border-gray-700 bg-[#dcedf7]">
        <CardHeader className="title text-3xl justify-center items-center">
          PARTYGAME
        </CardHeader>
        <CardContent className="flex flex-col justify-center items-center">
          <Button
            className="title rounded-full bg-[#711DB0] p-8 text-3xl"
            onClick={() => newGame()}
          >
            New Game!
          </Button>
          <p className="my-4">OR</p>
          <Input
            className="border-2 border-gray-700 bg-white"
            autoFocus
            value={room}
            placeholder="room code (ABCD)"
            onChange={(e) => setRoom(e.target.value.toUpperCase())}
          />
          <br />
          <Button
            className="title rounded-full bg-[#C21292] p-8 text-3xl"
            disabled={!room && room.length !== ROOM_CODE_LENGTH}
            onClick={() => joinGame()}
          >
            Join Game!
          </Button>
        </CardContent>
      </Card>
    </div>
  );
}
