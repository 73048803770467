import { useEffect, useState } from "react";
import ConfettiExplosion from "react-confetti-explosion";
import { useParams } from "react-router-dom";

import { cn } from "./lib/utils";
import { IRound, IUser } from "./types";
import { PartyMembers } from "./components/PartyMembers";
import { cardpacks } from "./data";

export function Leader({
  socket,
  user,
  round,
  members,
}: {
  socket: any;
  user: Partial<IUser>;
  round: IRound;
  members: IUser[];
}) {
  const { roomId } = useParams();
  const [pickedWinner, setPickedWinner] = useState<boolean>(false);
  const [selectedPack, setSelectedPack] = useState<(typeof cardpacks)[0]>(
    cardpacks[Math.floor(Math.random() * cardpacks.length)]
  );
  const selectPack = (pack: (typeof cardpacks)[0]) => {
    setSelectedPack(pack);
    socket.send(JSON.stringify({ type: "theme", payload: pack }));
  };

  const hasWinner = round?.submissions?.find((s) => s.won);

  useEffect(() => {
    if (!hasWinner) return;
    setTimeout(() => {
      socket.send(JSON.stringify({ type: "next" }));
    }, 3000);
  }, [hasWinner]);

  useEffect(() => {
    console.log({ roundCompleted: round?.completed })
    if (!round?.completed) return;
    if (hasWinner) return;
    const ding = new Audio("/audio/ding-1.mp3");
    ding.play();
  }, [round?.completed]);

  const chooseWinner = (submission: IRound["submissions"][0]) => {
    if (pickedWinner) return;
    setPickedWinner(true);
    socket?.send(JSON.stringify({ type: "winner", payload: submission }));
  };
  return (
    <div className="flex flex-col justify-between w-full h-full">
      {members.length !== 1 ? (
        <PartyMembers user={user} members={members} />
      ) : null}
      {round?.completed ? (
        <div className="flex flex-1 flex-col justify-center items-center w-full h-full overflow-scroll">
          <div className="text-2xl my-2">Choose the winner:</div>

          <div className="flex flex-row">
            {round.submissions.map((s) => {
              const winner = s.won;
              return (
                <div
                  key={s.id}
                  className="flex flex-col items-center mb-20 justify-center"
                >
                  <div className="flex p-5 my-8 rounded-full bg-green-300 justify-center items-center mr-2">
                    {members.find((m) => m.id === s.playerId)?.username}
                  </div>
                  {pickedWinner && winner && <ConfettiExplosion />}
                  <img
                    src={s.url}
                    className={cn(
                      "p-1 border-2 raised border-black rounded-lg bg-[#711DB0] h-[300px] w-[300px]",
                      winner && "border-4 border-green-500"
                    )}
                    onClick={() => chooseWinner(s)}
                  />
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div className="flex flex-1 flex-col mb-20 w-full">
          {members.length === 1 ? (
            <div className="flex flex-col items-center mb-20 justify-center w-full h-full">
              <div className="flex text-4xl mt-8">
                Invite your friends to join!
              </div>
              <div className="flex text-4xl my-8">Game Code:</div>
              <div className="flex justify-center items-center p-8 border-2 border-black rounded-lg bg-[#711DB0] text-white text-4xl">
                {roomId}
              </div>
            </div>
          ) : (
            <div className="flex flex-col justify-center items-center">
              <div className="mt-8 text-2xl">You are the leader</div>
              <div className="mt-8">Waiting for players to submit...</div>
              <div className="mt-8 text-2xl">Choose the next theme:</div>
              <div className="flex flex-row justify-center items-center">
                {cardpacks.map((pack) => {
                  const active = pack.id === selectedPack?.id;
                  return (
                    <div
                      key={pack.id}
                      onClick={() => selectPack(pack)}
                      className={cn(
                        "p-8 raised text-2xl text-white bg-[#711DB0] mr-2 rounded-lg cursor-pointer border-2 border-black",
                        active && "bg-[#C21292]"
                      )}
                    >
                      {pack.name}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
